html {
  // color
  --color-primary: #0e0e14; // Primary color - $color-neutral-900
  --color-jobs: #004099; // Jobs.ch primary color - $color-jobs-brand-60-base
  --color-job-scout-24: #ff7500; // Jobscout24 primary color
  --color-jobup: #4b820d; // JobUp primary color - $color-jobup-brand-50-base
  --color-neutral-200: #d2d3d9; // Icon color (used as a variable var(--color-neutral-200)
  --color-neutral-600: #585d72; // Icon color (used as a variable var(--color-neutral-600)

  // menu
  --mat-menu-item-label-text-size: 0.875rem; // 14px
  --mat-menu-item-label-text-tracking: 0;

  // button
  --mdc-filled-button-container-color: transparent;
  --mdc-filled-button-label-text-color: #d2d3d9; // colors.$color-neutral-200;

  // tooltip
  --mdc-plain-tooltip-container-color: #313751; // $color-neutral-700
  --mdc-plain-tooltip-supporting-text-color: #fff;

  // letter-spacing
  --mdc-typography-button-letter-spacing: 0;
  --mdc-text-button-label-text-tracking: 0;
  --mdc-filled-button-label-text-tracking: 0;
  --mdc-outlined-button-label-text-tracking: 0;
  --mdc-protected-button-label-text-tracking: 0;
  --mat-table-header-headline-tracking: 0;
  --mat-table-row-item-label-text-tracking: 0;
  --mat-table-footer-supporting-text-tracking: 0;
  --mat-datepicker-calendar-text-tracking: 0;
  --mat-stepper-container-text-tracking: 0;
  --mat-stepper-header-label-text-tracking: 0;
  --mat-toolbar-title-text-tracking: 0;
  --mat-body-2-tracking: 0;
  --mat-body-tracking: 0;

  // checkbox
  --mdc-checkbox-state-layer-size: 2rem;
  --mdc-checkbox-unselected-hover-icon-color: var(--mdc-checkbox-unselected-icon-color);
  --mdc-checkbox-selected-hover-state-layer-opacity: 1;
  --mdc-checkbox-selected-focus-state-layer-opacity: 1;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 1;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 1;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 1;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 1;
  --mdc-checkbox-selected-hover-state-layer-color: #e6e6ea; // colors.$color-neutral-100
  --mdc-checkbox-selected-focus-state-layer-color: #d2d3d9; // colors.$color-neutral-200;
  --mdc-checkbox-selected-pressed-state-layer-color: #d2d3d9; // colors.$color-neutral-200;
  --mdc-checkbox-unselected-hover-state-layer-color: #e6e6ea; // colors.$color-neutral-100
  --mdc-checkbox-unselected-focus-state-layer-color: #d2d3d9; // colors.$color-neutral-200;
  --mdc-checkbox-unselected-pressed-state-layer-color: #d2d3d9; // colors.$color-neutral-200;

  // form-field
  --mat-form-field-container-text-size: 0.875rem; // 14px
  --mat-form-field-container-text-tracking: 0;
  --mat-form-field-container-vertical-padding: 0.875rem; // 14px
  --mat-form-field-container-height: 3.4375rem; // 55px
  --mat-form-field-subscript-text-line-height: 1.176rem; // 18.81px
  --mat-form-field-subscript-text-size: 0.656rem; // 10.5px;
  --mat-form-field-subscript-text-tracking: 0;
  --mat-form-field-outlined-label-text-populated-size: 0.875rem; // 14px

  // label
  .mdc-form-field {
    --mat-checkbox-label-text-tracking: 0;
  }

  // text-field
  --mdc-outlined-text-field-label-text-size: 0.875rem; // 14px
  --mdc-outlined-text-field-outline-color: #d2d3d9; // colors.$color-neutral-200;
  --mdc-outlined-text-field-container-shape: 0.3125rem; // 5px
  --mdc-outlined-text-field-label-text-tracking: 0;

  // select / option
  --mat-select-trigger-text-tracking: 0;
  --mat-select-trigger-text-size: 0.875rem; // 14px
  --mat-option-label-text-tracking: 0;
  --mat-option-label-text-size: 0.875rem; // 14px

  // radio
  --mdc-radio-state-layer-size: 32px;

  // ripple
  --mat-ripple-color: #e6e6ea; // colors.$color-neutral-100
}
