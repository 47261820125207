@use 'styles/functions' as f;

$tooltip-info-color: #202333;
$tooltip-success-color: #067e54;
$tooltip-error-color: #c73618;
$tooltip-arrow-distance: f.rem-calc(4px);
$tooltip-arrow-size: f.rem-calc(6px);
$tooltip-padding-vertical: f.rem-calc(8px);
$tooltip-padding-horizontal: f.rem-calc(12px);

@mixin tooltip-type($color) {
  // stylelint-disable-next-line selector-class-pattern
  .mdc-tooltip__surface {
    --mdc-plain-tooltip-container-color: #{$color};
    --mdc-plain-tooltip-supporting-text-size: f.rem-calc(14px);

    padding: $tooltip-padding-vertical $tooltip-padding-horizontal;
    position: relative;
  }
}

@mixin tooltip-arrow($color) {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: $tooltip-arrow-size solid transparent;
  border-right: $tooltip-arrow-size solid transparent;
  border-bottom: $tooltip-arrow-size solid $color;
}

.tooltip-info {
  @include tooltip-type($tooltip-info-color);
}

.ui-tooltip.mdc-tooltip::after {
  @include tooltip-arrow($tooltip-info-color);
}

.tooltip-success {
  @include tooltip-type($tooltip-success-color);

  &.ui-tooltip.mdc-tooltip::after {
    @include tooltip-arrow($tooltip-success-color);
  }
}

.tooltip-error {
  @include tooltip-type($tooltip-error-color);

  &.ui-tooltip.mdc-tooltip::after {
    @include tooltip-arrow($tooltip-error-color);
  }
}

.mat-mdc-tooltip-panel-below {
  margin-top: $tooltip-arrow-distance;

  .ui-tooltip.mdc-tooltip::after {
    top: -$tooltip-arrow-size;
    left: calc(50% - $tooltip-arrow-size);
    transform: rotate(0);
  }
}

.mat-mdc-tooltip-panel-above {
  margin-bottom: $tooltip-arrow-distance;

  .ui-tooltip.mdc-tooltip::after {
    top: 100%;
    left: calc(50% - $tooltip-arrow-size);
    transform: rotate(180deg);
  }
}

.mat-mdc-tooltip-panel-right {
  margin-left: $tooltip-arrow-distance;

  .ui-tooltip.mdc-tooltip::after {
    left: calc(-1.5 * $tooltip-arrow-size);
    top: calc(50% - 0.5 * $tooltip-arrow-size);
    transform: rotate(270deg);
  }
}

.mat-mdc-tooltip-panel-left {
  margin-right: $tooltip-arrow-distance;

  .ui-tooltip.mdc-tooltip::after {
    right: calc(-1.5 * $tooltip-arrow-size);
    top: calc(50% - 0.5 * $tooltip-arrow-size);
    transform: rotate(90deg);
  }
}
