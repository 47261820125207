/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/mixins';
@use 'styles/colors';
@use 'styles/variables';

$gray-primary: colors.$gray-primary;

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-arrow {
  font-style: normal;
  width: 25px !important;
  height: 25px !important;
  border-left: 0 solid transparent !important;
  border-right: 0 solid transparent !important;
  border-top: 0 solid !important;
  margin: -6px 4px !important;
  color: $gray-primary;

  &::after {
    position: absolute;
    font-family: 'marketplace', serif !important;
    content: '\e313';
    font-size:  1.8em;
    right: 0;
  }
}

// .mat-select-trigger {
// text-align: right !important;
// }
